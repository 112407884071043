<template>
    <div class="flex flex-col lang-icon">
        <span class="roboto-mono fs-16" :title="uiTitle">{{ uiLangCode.toUpperCase() }}</span>
        <template v-if="uiLangCode !== dataLangCode">
            <hr>
            <span class="roboto-mono fs-16" :title="dataTitle">{{ dataLangCode.toUpperCase() }}</span>
        </template>
    </div>
</template>

<script setup lang="ts">
import { useAppStore } from '#imports'
import { useTranslation } from 'i18next-vue'
import { systemLanguageByCode } from '~/utils/languages'

const { t } = useTranslation()
const appStore = useAppStore()
const uiLangCode = computed(() => appStore.langUI)
const dataLangCode = computed(() => appStore.langData)
const uiTitle = computed(() => `${t('@interface')}: ${systemLanguageByCode(uiLangCode.value).nameEn} (${systemLanguageByCode(uiLangCode.value).name})`)
const dataTitle = computed(() => `${t('@data')}: ${systemLanguageByCode(dataLangCode.value).nameEn} (${systemLanguageByCode(dataLangCode.value).name})`)
</script>

<style>
.lang-icon hr {
    width: 26px;
    border-color: var(--app-color);
    border: 1px solid;
}
</style>
