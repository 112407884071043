<template>
    <div class="app-wrap">
        <AppLeftSidebar />
        <NuxtPage class="app-body" />
    </div>
</template>

<script setup lang="ts">
import AppLeftSidebar from '~/components/AppLeftSidebar.vue'
import { useAppPalette } from '~/utils/appPalette'

useAppPalette()
</script>
